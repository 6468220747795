import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Image, Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'react-phone-input-2/lib/style.css'
import axios from 'axios'
import {useNavigate, useParams} from "react-router-dom";


export function ActivationHeader() {
    return (
        <Row className="header mb-3 mb-xl-5 justify-content-end">
            <div className="col-5 col-sm-4 col-md-3 col-lg-2 p-1">
                <img src="/cardiehLogoTransparent2.png" alt="" className="img-fluid rounded" id="companyLogo"/>
            </div>
        </Row>
    );
}

export function LoadingSpinner() {
    return (
        <div className="d-flex justify-content-center align-items-center" id="loadingSpinnerDiv">
            <div className="spinner-border" role="status" id="loadingSpinner"/>
        </div>
    );
}

export function ActivationSuccess() {

    let {uid} = useParams();

    const [card, setCard] = useState( '' )
    /*const [description, setDescription] = useState( '' )*/
    const [content, setContent] = useState( '' )
    const [loaded, setLoaded] = useState( false )

    useEffect( () => {
        if ( !loaded ) {
            axios.get( '/api?uuid=' + uid )
                .then( value => {
                    if ( value.status === 200 && value.data[0].card_number ) {
                        /*console.log("hello description");*/
                        const cd = value.data[0];
                        const desc = JSON.parse( cd.description )
                        setCard( cd )
                        setLoaded( true )
                        setContent( desc.content )
                        console.log( desc.content )
                        /*setDescription( desc )*/
                        // this.setState( {mode: 'card', token: value.data.token, loginMessage: value.data.loginMessage} )
                        //this.setState( {loggedIn: value.data.loggedIn, token: value.data.token, loginMessage: value.data.loginMessage} )
                    }
                    console.log( value )
                } )
                .catch( reason => {
                    console.log( reason )
                    //this.setState( {loggedIn: false, token: '', loginMessage: '' + reason} )
                } );
        }
    } );


    return (
        <Container>
            <ActivationHeader/>
            {loaded &&
            <>
                <Row className="successText">
                        <Col className="col-12 col-sm-auto pe-sm-0">
                        <Image src={card.image} className="img-fluid float-start me-3 mb-3" fluid={true}/>
                        </Col>
                        <Col className="mb-4 col-12 col-sm-auto">
                            <h2>
                                {card.name}<br/>
                                {card.card_number_text} {card.card_number}<br/>
                                {/*{card.pin_text} {card.pin_code}*/}
                            </h2>
                        </Col>
                </Row>
                {
                    content.map( ( d ) =>
                        <Row key={d.title}>
                            <div className="successText">
                                <h4>{d.title}</h4>
                                <div dangerouslySetInnerHTML={{__html: d.description}}></div>
                            </div>
                        </Row>
                    )
                }
            </>
            }
        </Container>
    )
}

export function ActivationFail() {
    return (
        <Container className="ps-0 d-flex justify-content-start">
            <div className="mt-3" id="activationFailDiv">
                Please check activation code and try again
            </div>
        </Container>
    )
}

export function ActivationForm() {

    const [email, setEmail] = useState( '' );
    const [code, setCode] = useState( '' );
    const [validated, setValidated] = useState( false );
    const [codeValidity, setCodeValidity] = useState( true );
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleEmailInput = e => {
        setEmail( e.target.value )
    };
    const handleCodeInput = e => {
        setCode( e.target.value );
        setCodeValidity(true);
    };

    let navigate = useNavigate();

    const handleSubmit = ( event ) => {
        const form = event.currentTarget;
        const v = form.checkValidity();

        event.preventDefault();
        event.stopPropagation();
        setValidated( true );

        if ( v ) {
            setCodeValidity(true);
            setButtonDisabled(true);
            const params = new URLSearchParams();
            params.append( 'code', code )
            params.append( 'email', email )
            axios.post( '/api', params )
                .then( value => {
                    if ( value.status === 200 && value.data.valid ) {
                        setCodeValidity( true );
                        setButtonDisabled(false);
                        navigate( '/i/' + value.data.uid );

                    }
                    else if ( value.status === 200 && !value.data.valid ) {
                        setCodeValidity( false );
                        setButtonDisabled(false);
                    }
                } )
                .catch( reason => {
                    setButtonDisabled(false);
                    console.log( reason )


                    //this.setState( {loggedIn: false, token: '', loginMessage: '' + reason} )
                } );
        }
    }

    let loadingSpinner;
    if ( buttonDisabled ) {
        loadingSpinner = <LoadingSpinner/>;
    }

    return (
                <Container>
                    <ActivationHeader/>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-xl-5">
                        <Row>
                            <Col className="mb-3 col-lg-6 col-12">
                                <div id="companyInfo">
                                    <p className="fs-1 mb-3 mt-3 mb-lg-4 mt-lg-0 fw-bold">
                                        GET IN TOUCH
                                    </p>
                                    <p className="fs-5 lh-sm pt-lg-2">
                                        <img src="locationPin.png" alt=""/>
                                        <span className="ms-0 ms-sm-2">
                                            Address: No. 1206, Utrecht, The Netherlands
                                        </span>
                                    </p>
                                    <p className="fs-5 lh-sm">
                                        <img src="phonePic2.png" alt=""/>
                                        <span className="ms-0 ms-sm-2 mb-0">
                                          Primieh B.V.
                                        </span>
                                        <br/>
                                        <span className="ms-0 ms-sm-5">
                                          Company Registration Number RSIN: 858694
                                        </span>
                                    </p>
                                    <p className="fs-5 mb-0">
                                        <img src="letterPic.png" alt=""/>
                                        <a href="mailto:Support@cardieh.com" id="linkText">
                                            <span className="ms-0 ms-sm-2">
                                            Support@cardieh.com
                                            </span>
                                        </a>
                                    </p>
                                </div>
                            </Col>
                            <Col className="mb-3 col-lg-5 col-sm-9 col-12" xs={{ order: 'first' }} lg={{ order: 'last'}}>
                                <div id="activationInfo">
                                <p className="fs-1 mb-3 mt-3 mb-lg-0 mt-lg-0 fw-bold">
                                    CODE ACTIVATION
                                </p>
                                </div>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="smallLabel">Email address</Form.Label>
                                    <Form.Control
                                        className="bigPlaceholder"
                                        onChange={handleEmailInput}
                                        value={email}
                                        type="email"
                                        name="email"
                                        required
                                        placeholder="Your e-mail*"
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        Please enter your email correctly
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-4 paddingTop6px" controlId="activationCode">
                                    <Form.Label className="smallLabel">Activation code</Form.Label>
                                    <Form.Control
                                        className="bigPlaceholder"
                                        onChange={handleCodeInput}
                                        value={code}
                                        name="code"
                                        isValid={false}
                                        isInvalid={!codeValidity}
                                        required
                                        placeholder="Please enter your code"
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        Please check activation code and try again
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Button disabled={buttonDisabled} type="submit">
                                        Submit
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    {loadingSpinner}
                </Container>
    );
}